import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function SiteFooter() {
  return (
    <footer className="footer container-fluid py-5">
      <Container>
        <Row className="align-items-start">
          <Col md={4}>
            <h3>MEB Resources</h3>
          </Col>
          <Col md={4} >
            <h5>Follow Us</h5>
            <a href="https://facebook.com/MEBResources" className="mx-2">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/mebresources" className="mx-2">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </Col>
          <Col md={4}>
            <h5>Contact Us</h5>
            <p>
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              507-799-0076 / 507-313-4804
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              info@mebresources.com
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="text-center">
              &copy; {new Date().getFullYear()} MEB Resources. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default SiteFooter;